<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Coin Order
        <div class="card-header-actions">
          <a class="card-header-action" href="dinar-orders" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row>
          <b-col>
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="postOrder" novalidate action="https://vuejs.org/">
              <b-form-group id="provider"
                            label="Metode Pengiriman"
                            label-for="providers">
                <b-form-select id="providers"
                              :plain="true"
                              @change="getShippmentRates"
                              v-model="form.provider"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: 'rpx', text: 'RPX'},
                                                {value: 'paxel', text: 'PAXEL'}]">
                </b-form-select>
              </b-form-group>
              <b-form-group id="rContentInputGroup4"
                            label="Status Pembayaran"
                            label-for="rContent">
                <b-form-select id="status_pay"
                              :plain="true"
                              v-model.lazy.trim="$v.form.status_pay.$model"
                              :state="chkState('status_pay')"
                              aria-describedby="input1LiveFeedback1"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Status Pembayaran'},
                                                {value: 'Dibatalkan', text: 'Dibatalkan'},
                                                {value: 'Menunggu Pembayaran', text: 'Menunggu Pembayaran'},
                                                {value: 'Sudah Dibayar', text: 'Sudah Dibayar'}]">
                  </b-form-select>
                <b-form-invalid-feedback id="shippingAddressFeedback" v-for="(error , index) in errors.form.payment_status" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="order_payInputGroup4"
                            label="Harga Pengiriman"
                            label-for="order_pay">
                <b-form-input id="order_pay"
                              type="text"
                              v-model.trim="$v.form.order_pay.$model"
                              :state="chkState('order_pay')"
                              aria-describedby="input1LiveFeedback4"
                              placeholder="Harga Pengiriman"
                              autocomplete='address' />
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  This is a required field and must be valid e-mail address
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="paymentInputGroup1"
                            label="Payment Method"
                            label-for="payment">
                <b-form-select id="payment"
                              :plain="true"
                              :disabled="true"
                              v-model="payment"
                              autocomplete='given-name'
                              autofocus >
                              <option value="">Pilih payment</option>
                              <option v-if="this.payment == 'COD'" :value="payment">- {{ this.payment }}</option>
                              <option v-else v-for="(payment, index) in payments_method" :key="index" :value="payment.id">- {{ payment.payment_name }}</option>
                </b-form-select>
              </b-form-group>
              <b-form-group id="rContentInputGroup4"
                            v-if="levelStatus === 'stage'"
                            label="Status"
                            label-for="rContent">
                <b-form-select id="status"
                              :plain="true"
                              v-model.lazy.trim="$v.form.status.$model"
                              :state="chkState('status')"
                              aria-describedby="input1LiveFeedback1"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Status Pesanan'},
                                                {value: 'Dikirim', text: 'Dikirim'},
                                                {value: 'Dibatalkan', text: 'Dibatalkan'},
                                                {value: 'Menunggu Pembayaran', text: 'Menunggu Pembayaran'},
                                                {value: 'Sedang Diproses', text: 'Sedang Diproses'},
                                                {value: 'Delivered', text: 'Delivered'}]">
                </b-form-select>
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  This is a required field and must be valid e-mail address
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="rContentInputGroup4"
                            v-else
                            label="Status"
                            label-for="rContent">
                <b-form-select id="status"
                              :plain="true"
                              v-model.lazy.trim="$v.form.status.$model"
                              :state="chkState('status')"
                              aria-describedby="input1LiveFeedback1"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Status Pesanan'},
                                                {value: 'Dikirim', text: 'Dikirim'},
                                                {value: 'Dibatalkan', text: 'Dibatalkan'},
                                                {value: 'Menunggu Pembayaran', text: 'Menunggu Pembayaran'},
                                                {value: 'Sedang Diproses', text: 'Sedang Diproses'}]">
                </b-form-select>
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  This is a required field and must be valid e-mail address
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="emailInputGroup4"
                            label="Nomor Resi"
                            label-for="n_resi">
                <b-form-input id="n_resi"
                              type="text"
                              v-model.trim="form.n_resi"
                              aria-describedby="trackingFeedback"
                              placeholder="Nomor Resi"
                              autocomplete='email' />
              </b-form-group>
              <b-form-group id="lastNameInputGroup2"
                            label="Note Untuk Customer"
                            label-for="lastName">
                <b-form-textarea id="content"
                              v-model.trim="form.content"
                              aria-describedby="input1LiveFeedback4"
                              placeholder="Note ...">
                </b-form-textarea>
              </b-form-group>
              <b-form-group id="lastNameInputGroup2"
                            label="Customer Note"
                            label-for="lastName">
                <b-form-textarea id="customerNote"
                              v-model.trim="customerNote"
                              aria-describedby="input1LiveFeedback4"
                              placeholder="Note ...">
                </b-form-textarea>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import axios from 'axios'
import { required} from "vuelidate/lib/validators"

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        status_pay: "",
        status: "",
        order_pay: "",
        provider: "",
        content: "",
        payment_method: "",
        n_resi: "",
        customerNote: ""
      },
      levelStatus: process.env.VUE_APP_LEVEL,
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          payment_status: [],
          shipping_fee: [],
          tracking_no: [],
          note_to_customer: [],
          customer_note: [],
          status: []
        },
      },
      items: [],
      product: "",
      postal_code: "",
      products: [],
      payment: '',
      payments_method: [],
      tracking: '',
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      payment_method: {
        required
      },
      content: {
        required
      },
      order_pay: {
        required
      },
      status: {
        required
      },
      status_pay: {
        required
      },
      customerNote: {
        required
      }
    }
  },
  methods: {
    loadPayment() {

      this.$http.get(`payment-saved` ,{
        params: {
          payments: this.payment
        }
      }).then((result) => {
        this.payments_method = result.data.meta.data.payments
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    },
    postOrder() {
      this.validate()
      this.isLoading = true
          const formData = new FormData();
          formData.append("payment_status", this.form.status_pay);
          formData.append("shipping_fee", this.form.order_pay);
          formData.append("status", this.form.status);
          formData.append("tracking_no", this.form.n_resi == "" ? null : this.form.n_resi);
          formData.append("note_to_customer", this.form.content == "" ? null : this.form.content);
          formData.append("customer_note", this.form.customerNote);

          this.$http.post(`order-update/`  + this.$route.params.id, formData)
          .then(() => {
            this.isLoading = false
            this.$router.push({name : 'List Koin Nusantara Order'})
            this.$toasted.success('Koin Nusantara Order successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.payment_status = this.errors.message.payment_status;
                this.errors.form.description = this.errors.message.description;
                this.errors.form.gram_per_unit = this.errors.message.gram_per_unit;
                this.errors.form.unit = this.errors.message.unit;
                this.errors.form.sku = this.errors.message.sku;
                this.errors.form.minting_fee = this.errors.message.minting_fee;
                this.errors.form.booking_fee = this.errors.message.booking_fee;
                this.errors.form.image = this.errors.message.image;
              }
            }
          })

    },

    getShippmentRates(val) {
      let totalGram;
      for (let i = 0; i < this.items.length; i++) {
        totalGram = this.items[i].gram_per_unit * this.items[i].qty
      }

      const formData = new FormData();
      formData.append("provider", val);
      formData.append("postal_code", this.post_code);
      formData.append("weight", totalGram / 1000);

      axios.post(`https://service.treasury.id/api/v1/antigrvty/shipping/rates`, formData, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then((result) => {
        this.form.order_pay = result.data.data.fee
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.status = error.response.data.meta.code;
          this.errors.headers = error.response.headers;
          if(this.errors.status == 422) {
            this.$toasted.error('Please fill out the form that must be required')
            this.errors.message = error.response.data.meta.message;
          }else if(this.errors.status == 404) {
            this.sold = error.response.data.meta.message;
            this.$toasted.error(this.sold)
          }
        }
      })

    },

    onReset() {
      // Reset validation
      this.form = Object.assign({},)
      this.submitted = false
      this.$nextTick(() => {
        this.$v.$reset()
        this.feedBack = 'secondary'
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
  mounted () {
    this.loadPayment();
  },
  created () {
    this.$http.put(`order-data/` + this.$route.params.id)
    .then((result) => {
      this.form.status_pay = result.data.payment_status;
      this.items = result.data.items;
      this.form.order_pay = result.data.shipping_fee;
      this.post_code = result.data.postal_code;
      this.payment = result.data.payment_method;
      this.codStatus = result.data.status;
      if (this.codStatus == 'Received') {
        this.form.status = 'Menunggu Pembayaran'
      }else{
        this.form.status = this.codStatus;
      }
      this.form.provider = result.data.shipping_provider;
      this.form.n_resi = result.data.tracking_no;
      this.form.content = result.data.note_to_customer;
      this.tracking = this.form.n_resi;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
